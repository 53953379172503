import { Layout, TextContainer } from "@shopify/polaris";

import React from "react";

const ErrorPage = () => (
  <Layout>
    <Layout.Section>
      <div className="flex flex-col items-center justify-center mt-12">
        <TextContainer>
          Uh oh, looks like something broke. You can refresh the page, get mad
          at me in our Discord, or try again later!
        </TextContainer>

        <iframe
          className="mt-12"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/hV3WQW7n2BU?controls=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </Layout.Section>
  </Layout>
);

export default ErrorPage;
