const apiToken =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_PUBLIC_KEY
    : process.env.REACT_APP_PUBLIC_KEY;

const numberWithCommas = (number) => {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
};

const sortOptions = [
  { label: "Least required materials", value: "items" },
  { label: "Least cost per 1000xp", value: "cp1kxp" },
  { label: "Least ore", value: "ore" },
  { label: "Least wood", value: "wood" },
  { label: "Least hide", value: "hide" },
  { label: "Least cloth", value: "cloth" },
  { label: "Least gems", value: "gemstone" },
  { label: "Least reagents (flux, sandpaper, etc)", value: "reagent" },
  { label: "Least annoying", value: "annoyanceScale" },
  { label: "Least difficult", value: "difficultyScale" },
];

const findSkillById = (skills, id) => {
  return skills.find((skill) => skill.id === id);
};

const formatNumber = (num, digits = 1) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "m" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

const sortResources = (resources) => {
  return resources.sort(function (a, b) {
    const labelA = a.details.label.toUpperCase();
    const labelB = b.details.label.toUpperCase();

    return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
  });
};

function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const getItemCost = (itemPrices, resources) => {
  const result = {
    cost: 0,
    costPerItem: 0,
    incomplete: false,
  };

  resources.forEach((resource) => {
    const resourceLabel = resource.details.label;

    if (
      resourceLabel in itemPrices &&
      (itemPrices[resourceLabel]?.price || 0) > 0
    ) {
      result.cost += itemPrices[resourceLabel].price * resource.quantity;
      result.costPerItem += itemPrices[resourceLabel].price;
    } else result.incomplete = true;
  });

  return {
    ...result,
    cost: Math.ceil(result.cost),
  };
};

const formatGold = (amount = 0) => {
  const parsedAmount = (amount * 100) / 100;

  return `$${numberWithCommas(parsedAmount.toFixed(2)).replace("-", "")}`;
};

const getRandomItem = (items) => {
  return items[Math.floor(Math.random() * items.length)];
};

const sortArray = (array, getValue) => {
  return [...array].sort((a, b) => getValue(a) - getValue(b));
};

const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 2) {
    return Math.floor(interval) + " minutes ago";
  }
  if (interval > 1) {
    return Math.floor(interval) + " minute ago";
  }
  return "just now";
};

const clientSideSortMethods = ["cp1kxp"];

export {
  numberWithCommas,
  sortOptions,
  findSkillById,
  formatNumber,
  sortResources,
  debounce,
  getItemCost,
  formatGold,
  apiToken,
  getRandomItem,
  sortArray,
  timeSince,
  clientSideSortMethods,
};
