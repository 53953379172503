const table = {
  1: 108,
  2: 221,
  3: 339,
  4: 463,
  5: 592,
  6: 727,
  7: 867,
  8: 1012,
  9: 1163,
  10: 1319,
  11: 1481,
  12: 1648,
  13: 1820,
  14: 1998,
  15: 2181,
  16: 2370,
  17: 2564,
  18: 2763,
  19: 2968,
  20: 3178,
  21: 3394,
  22: 3615,
  23: 3841,
  24: 4073,
  25: 4310,
  26: 4553,
  27: 4801,
  28: 5054,
  29: 5313,
  30: 5577,
  31: 5847,
  32: 6122,
  33: 6402,
  34: 6688,
  35: 6979,
  36: 7276,
  37: 7578,
  38: 7885,
  39: 8198,
  40: 8516,
  41: 8840,
  42: 9169,
  43: 9503,
  44: 9843,
  45: 10188,
  46: 10539,
  47: 10895,
  48: 11256,
  49: 11623,
  50: 11995,
  51: 12751,
  52: 13582,
  53: 14489,
  54: 15471,
  55: 16529,
  56: 17663,
  57: 18872,
  58: 20157,
  59: 21517,
  60: 22953,
  61: 24465,
  62: 26052,
  63: 27715,
  64: 29453,
  65: 31267,
  66: 33157,
  67: 35122,
  68: 37163,
  69: 39279,
  70: 41471,
  71: 43739,
  72: 46082,
  73: 48501,
  74: 50995,
  75: 53565,
  76: 56211,
  77: 58932,
  78: 61729,
  79: 64601,
  80: 67549,
  81: 70573,
  82: 73672,
  83: 76847,
  84: 80097,
  85: 83423,
  86: 86825,
  87: 90302,
  88: 93855,
  89: 97483,
  90: 101187,
  91: 104967,
  92: 108822,
  93: 112753,
  94: 116759,
  95: 120841,
  96: 124999,
  97: 129232,
  98: 133541,
  99: 137925,
  100: 142385,
  101: 149926,
  102: 157864,
  103: 166198,
  104: 174929,
  105: 184057,
  106: 193582,
  107: 203504,
  108: 213823,
  109: 224539,
  110: 235652,
  111: 247162,
  112: 259069,
  113: 271372,
  114: 284072,
  115: 297169,
  116: 310663,
  117: 324554,
  118: 338842,
  119: 353527,
  120: 368609,
  121: 384088,
  122: 399964,
  123: 416236,
  124: 432905,
  125: 449971,
  126: 467434,
  127: 485294,
  128: 503551,
  129: 522205,
  130: 541256,
  131: 560704,
  132: 580549,
  133: 600790,
  134: 621428,
  135: 642463,
  136: 663895,
  137: 685724,
  138: 707950,
  139: 730573,
  140: 753593,
  141: 777010,
  142: 800824,
  143: 825034,
  144: 849641,
  145: 874645,
  146: 900046,
  147: 925844,
  148: 952039,
  149: 978631,
  150: 1005620,
  151: 1042628,
  152: 1081486,
  153: 1122194,
  154: 1164753,
  155: 1209162,
  156: 1255422,
  157: 1303532,
  158: 1353492,
  159: 1405303,
  160: 1458964,
  161: 1514476,
  162: 1571838,
  163: 1631050,
  164: 1692113,
  165: 1755026,
  166: 1819790,
  167: 1886404,
  168: 1954868,
  169: 2025183,
  170: 2097348,
  171: 2171364,
  172: 2247230,
  173: 2324946,
  174: 2404513,
  175: 2485930,
  176: 2569198,
  177: 2654316,
  178: 2741284,
  179: 2830103,
  180: 2920772,
  181: 3013292,
  182: 3107662,
  183: 3203882,
  184: 3301953,
  185: 3401874,
  186: 3503646,
  187: 3607268,
  188: 3712740,
  189: 3820063,
  190: 3929236,
  191: 4040260,
  192: 4153134,
  193: 4267858,
  194: 4384433,
  195: 4502858,
  196: 4623134,
  197: 4745260,
  198: 4869236,
  199: 4995063,
  200: 5122740,
  201: 5252268,
  202: 5382567,
  203: 5513637,
  204: 5645478,
  205: 5778090,
  206: 5911473,
  207: 6045627,
  208: 6180552,
  209: 6316248,
  210: 6452715,
  211: 6589953,
  212: 6727962,
  213: 6866742,
  214: 7006293,
  215: 7146615,
  216: 7287708,
  217: 7429572,
  218: 7572207,
  219: 7715613,
  220: 7859790,
  221: 8004738,
  222: 8150457,
  223: 8296947,
  224: 8444208,
  225: 8592240,
  226: 8741043,
  227: 8890617,
  228: 9040962,
  229: 9192078,
  230: 9343965,
  231: 9496623,
  232: 9650052,
  233: 9804252,
  234: 9959223,
  235: 10114965,
  236: 10271478,
  237: 10428762,
  238: 10586817,
  239: 10745643,
  240: 10905240,
  241: 11065608,
  242: 11226747,
  243: 11388657,
  244: 11551338,
  245: 11714790,
  246: 11879013,
  247: 12044007,
  248: 12209772,
  249: 12376308,
  250: 12543615,
};

export default table;
