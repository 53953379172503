const table = {
  1: 3,
  2: 42,
  3: 66,
  4: 92,
  5: 120,
  6: 150,
  7: 182,
  8: 216,
  9: 252,
  10: 290,
  11: 330,
  12: 372,
  13: 416,
  14: 462,
  15: 510,
  16: 560,
  17: 612,
  18: 666,
  19: 722,
  20: 780,
  21: 840,
  22: 902,
  23: 966,
  24: 1032,
  25: 1100,
  26: 1170,
  27: 1242,
  28: 1316,
  29: 1392,
  30: 1470,
  31: 1550,
  32: 1632,
  33: 1716,
  34: 1802,
  35: 1890,
  36: 1980,
  37: 2072,
  38: 2166,
  39: 2262,
  40: 2360,
  41: 2460,
  42: 2562,
  43: 2666,
  44: 2772,
  45: 2880,
  46: 2990,
  47: 3102,
  48: 3216,
  49: 3332,
  50: 3450,
  51: 3630,
  52: 3828,
  53: 4044,
  54: 4278,
  55: 4530,
  56: 4800,
  57: 5088,
  58: 5394,
  59: 5718,
  60: 6060,
  61: 6420,
  62: 6798,
  63: 7194,
  64: 7608,
  65: 8040,
  66: 8490,
  67: 8958,
  68: 9444,
  69: 9948,
  70: 10470,
  71: 11010,
  72: 11568,
  73: 12144,
  74: 12738,
  75: 13350,
  76: 13980,
  77: 14628,
  78: 15294,
  79: 15978,
  80: 16680,
  81: 17400,
  82: 18138,
  83: 18894,
  84: 19668,
  85: 20460,
  86: 21270,
  87: 22098,
  88: 22944,
  89: 23808,
  90: 24690,
  91: 25590,
  92: 26508,
  93: 27444,
  94: 28398,
  95: 29370,
  96: 30360,
  97: 31368,
  98: 32394,
  99: 33438,
  100: 34500,
  101: 35850,
  102: 37290,
  103: 38820,
  104: 40440,
  105: 42150,
  106: 43950,
  107: 45840,
  108: 47820,
  109: 49890,
  110: 52050,
  111: 54300,
  112: 56640,
  113: 59070,
  114: 61590,
  115: 64200,
  116: 66900,
  117: 69690,
  118: 72570,
  119: 75540,
  120: 78600,
  121: 81750,
  122: 84990,
  123: 88320,
  124: 91740,
  125: 95250,
  126: 98850,
  127: 102540,
  128: 106320,
  129: 110190,
  130: 114150,
  131: 118200,
  132: 122340,
  133: 126570,
  134: 130890,
  135: 135300,
  136: 139800,
  137: 144390,
  138: 149070,
  139: 153840,
  140: 158700,
  141: 163650,
  142: 168690,
  143: 173820,
  144: 179040,
  145: 184350,
  146: 189750,
  147: 195240,
  148: 200820,
  149: 206490,
  150: 212250,
  151: 219810,
  152: 227640,
  153: 235740,
  154: 244110,
  155: 252750,
  156: 261660,
  157: 270840,
  158: 280290,
  159: 290010,
  160: 300000,
  161: 310260,
  162: 320790,
  163: 331590,
  164: 342660,
  165: 354000,
  166: 365610,
  167: 377490,
  168: 389640,
  169: 402060,
  170: 414750,
  171: 427710,
  172: 440940,
  173: 454440,
  174: 468210,
  175: 482250,
  176: 496560,
  177: 511140,
  178: 525990,
  179: 541110,
  180: 556500,
  181: 572160,
  182: 588090,
  183: 604290,
  184: 620760,
  185: 637500,
  186: 654510,
  187: 671790,
  188: 689340,
  189: 707160,
  190: 725250,
  191: 743610,
  192: 762240,
  193: 781140,
  194: 800310,
  195: 819750,
  196: 839460,
  197: 859440,
  198: 879690,
  199: 900210,
  200: 921000,
  201: 942060,
  202: 963174,
  203: 984342,
  204: 1005564,
  205: 1026840,
  206: 1048170,
  207: 1069554,
  208: 1090992,
  209: 1112484,
  210: 1134030,
  211: 1155630,
  212: 1177284,
  213: 1198992,
  214: 1220754,
  215: 1242570,
  216: 1264440,
  217: 1286364,
  218: 1308342,
  219: 1330374,
  220: 1352460,
  221: 1374600,
  222: 1396794,
  223: 1419042,
  224: 1441344,
  225: 1463700,
  226: 1486110,
  227: 1508574,
  228: 1531092,
  229: 1553664,
  230: 1576290,
  231: 1598970,
  232: 1621704,
  233: 1644492,
  234: 1667334,
  235: 1690230,
  236: 1713180,
  237: 1736184,
  238: 1759242,
  239: 1782354,
  240: 1805520,
  241: 1828740,
  242: 1852014,
  243: 1875342,
  244: 1898724,
  245: 1922160,
  246: 1945650,
  247: 1969194,
  248: 1992792,
  249: 2016444,
  250: 2040150,
};

export default table;
