import xp12543 from "./xp/12543";
import xp14894 from "./xp/14894";
import xp14665 from "./xp/14665";
import xp78324 from "./xp/78324";
import xp23114 from "./xp/23114";
import xp23047 from "./xp/23047";
import xp20508 from "./xp/20508";
import xp21830 from "./xp/21830";
import xp20401 from "./xp/20401";

const craftingSkills = [
  {
    label: "Cooking",
    id: "cooking",
    xp: xp20401,
  },
  {
    label: "Engineering",
    id: "engineering",
    xp: xp14894,
  },
  {
    label: "Arcana",
    id: "arcana",
    xp: xp12543,
  },
  {
    label: "Furnishing",
    id: "furnishing",
    xp: xp14665,
  },
  {
    label: "Weaponsmithing",
    id: "weaponsmithing",
    xp: xp14894,
  },
  {
    label: "Armoring",
    id: "armoring",
    xp: xp14894,
  },
  {
    label: "Jewelcrafting",
    id: "jewelcrafting",
    xp: xp78324,
  },
];

const refiningSkills = [
  {
    label: "Leatherworking",
    id: "leatherworking",
    xp: xp23114,
  },
  {
    label: "Smelting",
    id: "smelting",
    xp: xp23047,
  },
  {
    label: "Stonecutting",
    id: "stonecutting",
    xp: xp20508,
  },
  {
    label: "Weaving",
    id: "weaving",
    xp: xp21830,
  },
  {
    label: "Woodworking",
    id: "woodworking",
    xp: xp21830,
  },
];

const findSkillById = (type, skillId) =>
  (type === "crafting" ? craftingSkills : refiningSkills).find(
    ({ id }) => skillId === id
  );

export { craftingSkills, refiningSkills, findSkillById };
