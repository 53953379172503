import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, noIndex }) => {
  const appendedTitle = `NWXP - ${title}`;

  return (
    <Helmet>
      <title>{appendedTitle}</title>
      <meta name="description" content={description} />

      <meta name="og:title" content={appendedTitle} />
      <meta name="og:description" content={description} />

      {noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

export default SEO;
