const table = {
  1: 108,
  2: 221,
  3: 339,
  4: 463,
  5: 592,
  6: 727,
  7: 867,
  8: 1012,
  9: 1163,
  10: 1319,
  11: 1481,
  12: 1648,
  13: 1820,
  14: 1998,
  15: 2181,
  16: 2370,
  17: 2564,
  18: 2763,
  19: 2968,
  20: 3178,
  21: 3394,
  22: 3615,
  23: 3841,
  24: 4073,
  25: 4310,
  26: 4553,
  27: 4801,
  28: 5054,
  29: 5313,
  30: 5577,
  31: 5847,
  32: 6122,
  33: 6402,
  34: 6688,
  35: 6979,
  36: 7276,
  37: 7578,
  38: 7885,
  39: 8198,
  40: 8516,
  41: 8840,
  42: 9169,
  43: 9503,
  44: 9843,
  45: 10188,
  46: 10539,
  47: 10895,
  48: 11256,
  49: 11623,
  50: 11995,
  51: 12643,
  52: 13355,
  53: 14132,
  54: 14974,
  55: 15881,
  56: 16853,
  57: 17889,
  58: 18990,
  59: 20156,
  60: 21387,
  61: 22683,
  62: 24043,
  63: 25468,
  64: 26958,
  65: 28513,
  66: 30133,
  67: 31817,
  68: 33566,
  69: 35380,
  70: 37259,
  71: 39203,
  72: 41211,
  73: 43284,
  74: 45422,
  75: 47625,
  76: 49893,
  77: 52225,
  78: 54622,
  79: 57084,
  80: 59611,
  81: 62203,
  82: 64859,
  83: 67580,
  84: 70366,
  85: 73217,
  86: 76133,
  87: 79113,
  88: 82158,
  89: 85268,
  90: 88443,
  91: 91683,
  92: 94987,
  93: 98356,
  94: 101790,
  95: 105289,
  96: 108853,
  97: 112481,
  98: 116174,
  99: 119932,
  100: 123755,
  101: 129295,
  102: 135127,
  103: 141250,
  104: 147665,
  105: 154371,
  106: 161369,
  107: 168659,
  108: 176240,
  109: 184113,
  110: 192277,
  111: 200733,
  112: 209481,
  113: 218520,
  114: 227851,
  115: 237473,
  116: 247387,
  117: 257593,
  118: 268090,
  119: 278879,
  120: 289959,
  121: 301331,
  122: 312995,
  123: 324950,
  124: 337197,
  125: 349735,
  126: 362565,
  127: 375687,
  128: 389100,
  129: 402805,
  130: 416801,
  131: 431089,
  132: 445669,
  133: 460540,
  134: 475703,
  135: 491157,
  136: 506903,
  137: 522941,
  138: 539270,
  139: 555891,
  140: 572803,
  141: 590007,
  142: 607503,
  143: 625290,
  144: 643369,
  145: 661739,
  146: 680401,
  147: 699355,
  148: 718600,
  149: 738137,
  150: 757965,
  151: 781293,
  152: 805787,
  153: 831447,
  154: 858274,
  155: 886267,
  156: 915427,
  157: 945753,
  158: 977245,
  159: 1009904,
  160: 1043729,
  161: 1078721,
  162: 1114879,
  163: 1152203,
  164: 1190694,
  165: 1230351,
  166: 1271175,
  167: 1313165,
  168: 1356321,
  169: 1400644,
  170: 1446133,
  171: 1492789,
  172: 1540611,
  173: 1589599,
  174: 1639754,
  175: 1691075,
  176: 1743563,
  177: 1797217,
  178: 1852037,
  179: 1908024,
  180: 1965177,
  181: 2023497,
  182: 2082983,
  183: 2143635,
  184: 2205454,
  185: 2268439,
  186: 2332591,
  187: 2397909,
  188: 2464393,
  189: 2532044,
  190: 2600861,
  191: 2670845,
  192: 2741995,
  193: 2814311,
  194: 2887794,
  195: 2962443,
  196: 3038259,
  197: 3115241,
  198: 3193389,
  199: 3272704,
  200: 3353185,
  201: 3434833,
  202: 3516805,
  203: 3599101,
  204: 3681721,
  205: 3764665,
  206: 3847933,
  207: 3931525,
  208: 4015441,
  209: 4099681,
  210: 4184245,
  211: 4269133,
  212: 4354345,
  213: 4439881,
  214: 4525741,
  215: 4611925,
  216: 4698433,
  217: 4785265,
  218: 4872421,
  219: 4959901,
  220: 5047705,
  221: 5135833,
  222: 5224285,
  223: 5313061,
  224: 5402161,
  225: 5491585,
  226: 5581333,
  227: 5671405,
  228: 5761801,
  229: 5852521,
  230: 5943565,
  231: 6034933,
  232: 6126625,
  233: 6218641,
  234: 6310981,
  235: 6403645,
  236: 6496633,
  237: 6589945,
  238: 6683581,
  239: 6777541,
  240: 6871825,
  241: 6966433,
  242: 7061365,
  243: 7156621,
  244: 7252201,
  245: 7348105,
  246: 7444333,
  247: 7540885,
  248: 7637761,
  249: 7734961,
  250: 7832485,
};

export default table;
