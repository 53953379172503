import React from "react";
import { Link as RouterLink } from "react-router-dom";

const isOutboundLink = (url) => {
  return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
};

const Link = ({ children, url = "", ...rest }) => {
  if (isOutboundLink(url)  && !url.includes('nwxp.info')) {
    return (
      <a href={url} {...rest} target="_blank" rel="noreferrer noopener">
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={url} {...rest}>
      {children}
    </RouterLink>
  );
};

export default Link;
