import { Button, TopBar } from "@shopify/polaris";
import React, { useCallback, useContext, useState } from "react";

import { Consumer as SupabaseContextConsumer } from "../contexts/SupabaseContext";

const UserMenu = () => {
  const [active, setActive] = useState(false);

  const { signOut, user, userData } = useContext(SupabaseContextConsumer);

  const toggleActive = useCallback(() => setActive((prev) => !prev), []);

  const username = user?.user_metadata?.username || "";

  return user ? (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              url: "/account",
              content: "Account",
            },
            {
              content: "Sign out",
              onAction: () => signOut(),
            },
          ],
        },
      ]}
      name={username}
      detail={userData.sponsor_type}
      initials={username.split("")[0]}
      open={active}
      onToggle={toggleActive}
    />
  ) : (
    <div className="flex-shrink-0 mr-6">
      <Button primary url="/login">
        Sign in
      </Button>
    </div>
  );
};

export default UserMenu;
