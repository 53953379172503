const table = {
  1: 108,
  2: 222,
  3: 342,
  4: 468,
  5: 600,
  6: 738,
  7: 882,
  8: 1032,
  9: 1187,
  10: 1349,
  11: 1517,
  12: 1690,
  13: 1870,
  14: 2055,
  15: 2246,
  16: 2444,
  17: 2647,
  18: 2856,
  19: 3071,
  20: 3292,
  21: 3519,
  22: 3752,
  23: 3991,
  24: 4235,
  25: 4486,
  26: 4743,
  27: 5005,
  28: 5274,
  29: 5548,
  30: 5828,
  31: 6115,
  32: 6407,
  33: 6705,
  34: 7009,
  35: 7319,
  36: 7635,
  37: 7957,
  38: 8285,
  39: 8618,
  40: 8958,
  41: 9304,
  42: 9655,
  43: 10013,
  44: 10376,
  45: 10745,
  46: 11121,
  47: 11502,
  48: 11889,
  49: 12282,
  50: 12681,
  51: 14706,
  52: 16761,
  53: 18846,
  54: 20961,
  55: 23105,
  56: 25279,
  57: 27483,
  58: 29716,
  59: 31979,
  60: 34272,
  61: 36594,
  62: 38946,
  63: 41328,
  64: 43740,
  65: 46181,
  66: 48652,
  67: 51153,
  68: 53683,
  69: 56243,
  70: 58833,
  71: 61452,
  72: 64101,
  73: 66780,
  74: 69489,
  75: 72227,
  76: 74995,
  77: 77793,
  78: 80620,
  79: 83477,
  80: 86364,
  81: 89280,
  82: 92226,
  83: 95202,
  84: 98208,
  85: 101243,
  86: 104308,
  87: 107403,
  88: 110527,
  89: 113681,
  90: 116865,
  91: 120078,
  92: 123321,
  93: 126594,
  94: 129897,
  95: 133229,
  96: 136591,
  97: 139983,
  98: 143404,
  99: 146855,
  100: 150336,
  101: 168561,
  102: 186914,
  103: 205396,
  104: 224006,
  105: 242744,
  106: 261610,
  107: 280605,
  108: 299728,
  109: 318979,
  110: 338358,
  111: 357866,
  112: 377502,
  113: 397266,
  114: 417158,
  115: 437179,
  116: 457328,
  117: 477605,
  118: 498010,
  119: 518544,
  120: 539206,
  121: 559996,
  122: 580914,
  123: 601961,
  124: 623136,
  125: 644439,
  126: 665870,
  127: 687430,
  128: 709118,
  129: 730934,
  130: 752878,
  131: 774951,
  132: 797152,
  133: 819481,
  134: 841938,
  135: 864524,
  136: 887238,
  137: 910080,
  138: 933050,
  139: 956149,
  140: 979376,
  141: 1002731,
  142: 1026214,
  143: 1049826,
  144: 1073566,
  145: 1097434,
  146: 1121430,
  147: 1145555,
  148: 1169808,
  149: 1194189,
  150: 1218698,
  151: 1353698,
  152: 1490386,
  153: 1628761,
  154: 1768824,
  155: 1910574,
  156: 2054012,
  157: 2199137,
  158: 2345950,
  159: 2494450,
  160: 2644638,
  161: 2796513,
  162: 2950076,
  163: 3105326,
  164: 3262264,
  165: 3420889,
  166: 3581202,
  167: 3743202,
  168: 3906890,
  169: 4072265,
  170: 4239328,
  171: 4408078,
  172: 4578516,
  173: 4750641,
  174: 4924454,
  175: 5099954,
  176: 5277142,
  177: 5456017,
  178: 5636580,
  179: 5818830,
  180: 6002768,
  181: 6188393,
  182: 6375706,
  183: 6564706,
  184: 6755394,
  185: 6947769,
  186: 7141832,
  187: 7337582,
  188: 7535020,
  189: 7734145,
  190: 7934958,
  191: 8137458,
  192: 8341646,
  193: 8547521,
  194: 8755084,
  195: 8964334,
  196: 9175272,
  197: 9387897,
  198: 9602210,
  199: 9818210,
  200: 10035898,
  201: 10255273,
  202: 10476184,
  203: 10698641,
  204: 10922655,
  205: 11148237,
  206: 11375398,
  207: 11604150,
  208: 11834503,
  209: 12066468,
  210: 12300057,
  211: 12535281,
  212: 12772152,
  213: 13010681,
  214: 13250879,
  215: 13492759,
  216: 13736332,
  217: 13981610,
  218: 14228605,
  219: 14477329,
  220: 14727794,
  221: 14980012,
  222: 15233996,
  223: 15489758,
  224: 15747310,
  225: 16006665,
  226: 16267835,
  227: 16530834,
  228: 16795674,
  229: 17062367,
  230: 17330927,
  231: 17601367,
  232: 17873700,
  233: 18147940,
  234: 18424099,
  235: 18702191,
  236: 18982230,
  237: 19264229,
  238: 19548202,
  239: 19834163,
  240: 20122126,
  241: 20412105,
  242: 20704113,
  243: 20998166,
  244: 21294277,
  245: 21592461,
  246: 21892732,
  247: 22195105,
  248: 22499594,
  249: 22806215,
  250: 23114982,
};

export default table;
