const table = {
  1: 108,
  2: 221,
  3: 339,
  4: 463,
  5: 592,
  6: 727,
  7: 867,
  8: 1012,
  9: 1163,
  10: 1319,
  11: 1481,
  12: 1648,
  13: 1820,
  14: 1998,
  15: 2181,
  16: 2370,
  17: 2564,
  18: 2763,
  19: 2968,
  20: 3178,
  21: 3394,
  22: 3615,
  23: 3841,
  24: 4073,
  25: 4310,
  26: 4553,
  27: 4801,
  28: 5054,
  29: 5313,
  30: 5577,
  31: 5847,
  32: 6122,
  33: 6402,
  34: 6688,
  35: 6979,
  36: 7276,
  37: 7578,
  38: 7885,
  39: 8198,
  40: 8516,
  41: 8840,
  42: 9169,
  43: 9503,
  44: 9843,
  45: 10188,
  46: 10539,
  47: 10895,
  48: 11256,
  49: 11623,
  50: 11995,
  51: 12805,
  52: 13696,
  53: 14668,
  54: 15721,
  55: 16855,
  56: 18070,
  57: 19366,
  58: 20743,
  59: 22201,
  60: 23740,
  61: 25360,
  62: 27061,
  63: 28843,
  64: 30706,
  65: 32650,
  66: 34675,
  67: 36781,
  68: 38968,
  69: 41236,
  70: 43585,
  71: 46015,
  72: 48526,
  73: 51118,
  74: 53791,
  75: 56545,
  76: 59380,
  77: 62296,
  78: 65293,
  79: 68371,
  80: 71530,
  81: 74770,
  82: 78091,
  83: 81493,
  84: 84976,
  85: 88540,
  86: 92185,
  87: 95911,
  88: 99718,
  89: 103606,
  90: 107575,
  91: 111625,
  92: 115756,
  93: 119968,
  94: 124261,
  95: 128635,
  96: 133090,
  97: 137626,
  98: 142243,
  99: 146941,
  100: 151720,
  101: 160338,
  102: 169410,
  103: 178935,
  104: 188914,
  105: 199346,
  106: 210232,
  107: 221572,
  108: 233365,
  109: 245612,
  110: 258312,
  111: 271466,
  112: 285074,
  113: 299135,
  114: 313650,
  115: 328618,
  116: 344040,
  117: 359916,
  118: 376245,
  119: 393028,
  120: 410264,
  121: 427954,
  122: 446098,
  123: 464695,
  124: 483746,
  125: 503250,
  126: 523208,
  127: 543620,
  128: 564485,
  129: 585804,
  130: 607576,
  131: 629802,
  132: 652482,
  133: 675615,
  134: 699202,
  135: 723242,
  136: 747736,
  137: 772684,
  138: 798085,
  139: 823940,
  140: 850248,
  141: 877010,
  142: 904226,
  143: 931895,
  144: 960018,
  145: 988594,
  146: 1017624,
  147: 1047108,
  148: 1077045,
  149: 1107436,
  150: 1138280,
  151: 1183640,
  152: 1231268,
  153: 1281164,
  154: 1333328,
  155: 1387760,
  156: 1444460,
  157: 1503428,
  158: 1564664,
  159: 1628168,
  160: 1693940,
  161: 1761980,
  162: 1832288,
  163: 1904864,
  164: 1979708,
  165: 2056820,
  166: 2136200,
  167: 2217848,
  168: 2301764,
  169: 2387948,
  170: 2476400,
  171: 2567120,
  172: 2660108,
  173: 2755364,
  174: 2852888,
  175: 2952680,
  176: 3054740,
  177: 3159068,
  178: 3265664,
  179: 3374528,
  180: 3485660,
  181: 3599060,
  182: 3714728,
  183: 3832664,
  184: 3952868,
  185: 4075340,
  186: 4200080,
  187: 4327088,
  188: 4456364,
  189: 4587908,
  190: 4721720,
  191: 4857800,
  192: 4996148,
  193: 5136764,
  194: 5279648,
  195: 5424800,
  196: 5572220,
  197: 5721908,
  198: 5873864,
  199: 6028088,
  200: 6184580,
  201: 6343340,
  202: 6502730,
  203: 6662750,
  204: 6823400,
  205: 6984680,
  206: 7146590,
  207: 7309130,
  208: 7472300,
  209: 7636100,
  210: 7800530,
  211: 7965590,
  212: 8131280,
  213: 8297600,
  214: 8464550,
  215: 8632130,
  216: 8800340,
  217: 8969180,
  218: 9138650,
  219: 9308750,
  220: 9479480,
  221: 9650840,
  222: 9822830,
  223: 9995450,
  224: 10168700,
  225: 10342580,
  226: 10517090,
  227: 10692230,
  228: 10868000,
  229: 11044400,
  230: 11221430,
  231: 11399090,
  232: 11577380,
  233: 11756300,
  234: 11935850,
  235: 12116030,
  236: 12296840,
  237: 12478280,
  238: 12660350,
  239: 12843050,
  240: 13026380,
  241: 13210340,
  242: 13394930,
  243: 13580150,
  244: 13766000,
  245: 13952480,
  246: 14139590,
  247: 14327330,
  248: 14515700,
  249: 14704700,
  250: 14894330,
};

export default table;
