import { Select } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useSettings } from "../contexts/SettingsContext";
import queryString from "query-string";
import { apiToken } from "../utils/helpers";

const ServerSelector = () => {
  const [servers, setServers] = useState([]);
  const { settings, setSetting } = useSettings();

  useEffect(() => {
    fetch(
      `/api/v2/servers?${queryString.stringify({
        v: process.env.REACT_APP_VERSION_UNIQUE_STRING.slice(0, 3),
      })}`,
      {
        headers: {
          Authorization: `Token ${apiToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => setServers(data.servers));
  }, []);

  useEffect(() => {
    if (servers?.length && !settings.nwxServerId) {
      setSetting("nwxServerId", servers[0].id);
    }
  }, [servers, setSetting, settings.nwxServerId]);

  return (
    !!servers?.length && (
      <Select
        label="World"
        labelHidden
        options={servers?.map((server) => ({
          label: `${server.name} (${server.region})`,
          value: server.id,
        }))}
        value={settings.nwxServerId}
        onChange={(v) => setSetting("nwxServerId", v)}
      />
    )
  );
};

export default ServerSelector;
