import { DisplayText, Layout, TextContainer } from "@shopify/polaris";

import React from "react";

const NotFound = () => (
  <Layout>
    <Layout.Section>
      <div className="flex flex-col items-center justify-center mt-12">
        <DisplayText size="extraLarge">404</DisplayText>
        <TextContainer>
          Uh oh, this page doesn't exist. Maybe try choosing a page from the
          sidebar?
        </TextContainer>
      </div>
    </Layout.Section>
  </Layout>
);

export default NotFound;
