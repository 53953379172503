import {
  AppProvider,
  Banner,
  Frame,
  Navigation,
  Page,
  Spinner,
  TopBar,
  Link as PolarisLink,
} from "@shopify/polaris";
import { ChatMajor, HeartMajor, HomeMajor } from "@shopify/polaris-icons";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import { Suspense, lazy, useCallback, useState } from "react";
import { craftingSkills, refiningSkills } from "./utils/skills";

import UpdateModal from "./components/UpdateModal";
// import ContinueFromLastSpot from "./components/ContinueFromLastSpot";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./pages/Error";
import Link from "./components/Link";
import NotFound from "./pages/NotFound";
import { QueryParamProvider } from "use-query-params";
import SEO from "./components/SEO";
import ServiceWorkerNotification from "./components/ServiceWorkerNotification";
import { Provider as SupabaseContextProvider } from "./contexts/SupabaseContext";
import { SettingsContextProvider } from "./contexts/SettingsContext";
import UserMenu from "./components/UserMenu";
import logoSvg from "./assets/logo.svg";
import useAnalytics from "./hooks/useAnalytics";
import ServerSelector from "./components/ServerSelector";

const Skill = lazy(() => import("./pages/Skill"));
const Home = lazy(() => import("./pages/Home"));
const Feedback = lazy(() => import("./pages/Feedback"));
const Login = lazy(() => import("./pages/Login"));
const Account = lazy(() => import("./pages/Account"));
const Profit = lazy(() => import("./pages/Profit"));
const Shards = lazy(() => import("./pages/Shards"));
const Changelog = lazy(() => import("./pages/Changelog"));

const i18n = {
  Polaris: {
    Badge: {
      STATUS_LABELS: {
        new: "New",
      },
    },
    DropZone: {
      single: {
        overlayTextFile: "Drop file to upload",
        actionTitleFile: "Drag and drop file",
        actionHintFile: "or drop file to upload",
        labelFile: "Upload file",
      },
      errorOverlayTextFile: "File type is not valid",
    },
  },
};

const App = () => {
  useAnalytics();

  const [pageTitle, setPageTitle] = useState({});
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const location = useLocation();

  const handleSetPageTitle = useCallback(
    (title, description) => setPageTitle({ title, description }),
    []
  );

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive(
      (mobileNavigationActive) => !mobileNavigationActive
    );
  }, []);

  const handleNavClick = useCallback(() => {
    if (mobileNavigationActive) {
      setMobileNavigationActive(false);
    }
  }, [mobileNavigationActive]);

  const topBarMarkup = (
    <TopBar
      userMenu={<UserMenu />}
      searchField={
        <img className="block h-12 md:hidden" src={logoSvg} alt="Logo" />
      }
      showNavigationToggle
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  // const renderSkillButton = useCallback(
  //   (skillType, skill) => {
  //     const path = `/skills/${skillType}/${skill.id}`;
  //     const pathwayPath = `/skills/${skillType}/${skill.id}/pathway`;

  //     return {
  //       url: pathwayPath,
  //       label: skill.label,
  //       // selected: true,
  //       // selected: true,
  //       selected: true,
  //       subNavigationItems: [
  //         {
  //           url: pathwayPath,
  //           label: "Pathway",
  //           onClick: handleNavClick,
  //         },
  //         {
  //           url: path,
  //           label: "Item list",
  //           onClick: handleNavClick,
  //         },
  //       ],
  //     };
  //   },
  //   [handleNavClick]
  // );

  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <Frame
        logo={{
          width: 124,
          topBarSource: logoSvg,
          url: "/",
          accessibilityLabel: "NWXP",
        }}
        topBar={topBarMarkup}
        showNavigationToggle
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        navigation={
          <Navigation location={location.pathname}>
            <Navigation.Section
              items={[
                {
                  exactMatch: true,
                  label: "Home",
                  icon: HomeMajor,
                  url: "/",
                  onClick: handleNavClick,
                },
                {
                  url: "https://discord.gg/5HhAYxwj6X",
                  external: "true",
                  icon: ChatMajor,
                  label: "NWXP Discord",
                  onClick: handleNavClick,
                },
                {
                  label: "Sponsor NWXP",
                  icon: HeartMajor,
                  url: "https://www.patreon.com/nwxp",
                  external: "true",
                  onClick: handleNavClick,
                },
              ]}
            />

            <Navigation.Section
              title="XP Calculators"
              items={[
                {
                  label: "Crafting",
                  url: "/skills/crafting/engineering/pathway",
                  subNavigationItems: craftingSkills
                    .map((skill) => [
                      {
                        label: skill.label,
                        disabled: true,
                      },
                      {
                        url: `/skills/crafting/${skill.id}/pathway`,
                        label: "Pathway",
                        onClick: handleNavClick,
                      },
                      {
                        url: `/skills/crafting/${skill.id}`,
                        label: "Item list",
                        onClick: handleNavClick,
                      },
                    ])
                    .flat(),
                },
                {
                  label: "Refining",
                  url: "/skills/refining/leatherworking/pathway",
                  subNavigationItems: refiningSkills
                    .map((skill) => [
                      {
                        label: skill.label,
                        disabled: true,
                      },
                      {
                        url: `/skills/refining/${skill.id}/pathway`,
                        label: "Pathway",
                        onClick: handleNavClick,
                      },
                      {
                        url: `/skills/refining/${skill.id}`,
                        label: "Item list",
                        onClick: handleNavClick,
                      },
                    ])
                    .flat(),
                },
                // {
                //   label: "Refining",
                //   url: "/skills/refining/leatherworking/pathway",
                //   subNavigationItems: refiningSkills.map((skill) =>
                //     renderSkillButton("refining", skill)
                //   ),
                // },
              ]}
            />
            <Navigation.Section
              title="Tools"
              items={[
                // {
                //   label: "Craft calculator",
                //   url: "/craft",
                //   onClick: handleNavClick,
                // },
                {
                  label: "Umbral shard calculator",
                  url: "/shards",
                  onClick: handleNavClick,
                },
                {
                  label: "Item search",
                  url: "/items",
                  onClick: handleNavClick,
                },
              ]}
            />
            <Navigation.Section
              title="Links"
              items={[
                {
                  url: "/feedback",
                  label: "Leave feedback",
                  onClick: handleNavClick,
                },
                {
                  url: "/changelog",
                  label: "Changelog",
                  onClick: handleNavClick,
                },
                {
                  url: "https://discord.com/api/oauth2/authorize?client_id=909790625816256573&permissions=377957141504&scope=bot",
                  external: "true",
                  label: "NWXP Discord bot",
                  onClick: handleNavClick,
                },
                {
                  url: "https://play.google.com/store/apps/details?id=info.nwxp.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
                  external: "true",
                  label: "Android app",
                  onClick: handleNavClick,
                },
                {
                  url: "https://www.iubenda.com/privacy-policy/56758293",
                  label: "Privacy policy",
                  external: "true",
                  onClick: handleNavClick,
                },
              ]}
            />
            <Navigation.Section title="New World Exchange" items={[]} />
            <div style={{ margin: "-0.75rem 1.25rem 2rem 1.25rem" }}>
              <ServerSelector />
            </div>
          </Navigation>
        }
      >
        <Page
          fullWidth
          title={pageTitle.title}
          subtitle={pageTitle.description}
        >
          {/* <ContinueFromLastSpot /> */}
          <Banner status="critical">
            NWXP is updating, please check back later.
          </Banner>
          <UpdateModal />
          <ServiceWorkerNotification />
          <Suspense
            fallback={
              <div className="flex items-center justify-center w-full m-12">
                <Spinner accessibilityLabel="Items loading" size="large" />
              </div>
            }
          >
            <Banner status="info">
              <PolarisLink external url="https://new-world.exchange">
                New World Exchange
              </PolarisLink>{" "}
              is no longer available. To make the most out of NWXP, download
              prices from{" "}
              <PolarisLink external url="https://nwmarketprices.com">
                NW Market Prices
              </PolarisLink>{" "}
              to import on your{" "}
              <PolarisLink url="/account">account page</PolarisLink>.
            </Banner>
            <Switch>
              <Route exact path="/login">
                <Login setPageTitle={handleSetPageTitle} />
              </Route>
              {/* <Route exact path="/craft">
                <Items setPageTitle={handleSetPageTitle} />
              </Route> */}
              <Route exact path="/shards">
                <Shards setPageTitle={handleSetPageTitle} />
              </Route>
              <Route exact path="/account">
                <Account setPageTitle={handleSetPageTitle} />
              </Route>
              <Route exact path="/feedback">
                <Feedback setPageTitle={handleSetPageTitle} />
              </Route>
              <Route exact path="/changelog">
                <Changelog setPageTitle={handleSetPageTitle} />
              </Route>
              <Route exact path="/items">
                <Profit setPageTitle={handleSetPageTitle} />
              </Route>
              <Route exact path="/skills/:skillType/:skillId">
                <Skill setPageTitle={handleSetPageTitle} />
              </Route>
              <Route exact path="/skills/:skillType/:skillId/pathway">
                <Skill setPageTitle={handleSetPageTitle} pathway />
              </Route>
              <Route exact path="/">
                <Home setPageTitle={handleSetPageTitle} />
              </Route>
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Page>
      </Frame>
    </QueryParamProvider>
  );
};

const Navigator = () => {
  return (
    <ErrorBoundary FallbwackComponent={ErrorPage}>
      <SEO
        title="New World Crafting & Refining XP Calculator"
        description="NWXP is a crafting and refining XP calculator for New World showing you the best methods to level up your skills and stay on top of Aeternum."
      />
      <AppProvider colorScheme="light" linkComponent={Link} i18n={i18n}>
        <SettingsContextProvider>
          <SupabaseContextProvider>
            <Router>
              <App />
            </Router>
          </SupabaseContextProvider>
        </SettingsContextProvider>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default Navigator;
