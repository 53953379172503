import React, { useCallback, useEffect, useState } from "react";

import { Text, Modal, Link } from "@shopify/polaris";

const UpdateModal = () => {
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    if (countdown === 0) {
      window.location.href = "https://app.nwxp.info";
    }

    return () => clearInterval(interval);
  });

  return (
    <Modal
      instant
      open
      title={"NWXP update available!"}
      primaryAction={{
        content: `Redirecting in ${countdown}`,
        onAction: () => {
          window.location.href = "https://app.nwxp.info";
        },
      }}
      secondaryActions={[
        {
          content: "Go now",
          onAction: () => {
            window.location.href = "https://app.nwxp.info";
          },
        },
      ]}
      sectioned
    >
      <Text variant="bodyLg">
        NWXP has been overhauled, you can visit the updated version at{" "}
        <Link url="https://app.nwxp.info">app.nwxp.info</Link>. You will be
        automatically redirected shortly.
      </Text>
    </Modal>
  );
};

export default UpdateModal;
