const table = {
  1: 96,
  2: 197,
  3: 304,
  4: 416,
  5: 533,
  6: 656,
  7: 784,
  8: 917,
  9: 1055,
  10: 1199,
  11: 1348,
  12: 1502,
  13: 1662,
  14: 1826,
  15: 1996,
  16: 2172,
  17: 2352,
  18: 2538,
  19: 2729,
  20: 2925,
  21: 3127,
  22: 3334,
  23: 3546,
  24: 3763,
  25: 3986,
  26: 4214,
  27: 4447,
  28: 4686,
  29: 4930,
  30: 5179,
  31: 5434,
  32: 5694,
  33: 5959,
  34: 6229,
  35: 6505,
  36: 6786,
  37: 7072,
  38: 7364,
  39: 7660,
  40: 7962,
  41: 8270,
  42: 8582,
  43: 8900,
  44: 9223,
  45: 9551,
  46: 9885,
  47: 10224,
  48: 10568,
  49: 10917,
  50: 11272,
  51: 13072,
  52: 14899,
  53: 16752,
  54: 18632,
  55: 20538,
  56: 22470,
  57: 24429,
  58: 26414,
  59: 28426,
  60: 30464,
  61: 32528,
  62: 34619,
  63: 36736,
  64: 38880,
  65: 41050,
  66: 43246,
  67: 45469,
  68: 47718,
  69: 49994,
  70: 52296,
  71: 54624,
  72: 56979,
  73: 59360,
  74: 61768,
  75: 64202,
  76: 66662,
  77: 69149,
  78: 71662,
  79: 74202,
  80: 76768,
  81: 79360,
  82: 81979,
  83: 84624,
  84: 87296,
  85: 89994,
  86: 92718,
  87: 95469,
  88: 98246,
  89: 101050,
  90: 103880,
  91: 106736,
  92: 109619,
  93: 112528,
  94: 115464,
  95: 118426,
  96: 121414,
  97: 124429,
  98: 127470,
  99: 130538,
  100: 133632,
  101: 149832,
  102: 166146,
  103: 182574,
  104: 199116,
  105: 215772,
  106: 232542,
  107: 249426,
  108: 266424,
  109: 283536,
  110: 300762,
  111: 318102,
  112: 335556,
  113: 353124,
  114: 370806,
  115: 388602,
  116: 406512,
  117: 424536,
  118: 442674,
  119: 460926,
  120: 479292,
  121: 497772,
  122: 516366,
  123: 535074,
  124: 553896,
  125: 572832,
  126: 591882,
  127: 611046,
  128: 630324,
  129: 649716,
  130: 669222,
  131: 688842,
  132: 708576,
  133: 728424,
  134: 748386,
  135: 768462,
  136: 788652,
  137: 808956,
  138: 829374,
  139: 849906,
  140: 870552,
  141: 891312,
  142: 912186,
  143: 933174,
  144: 954276,
  145: 975492,
  146: 996822,
  147: 1018266,
  148: 1039824,
  149: 1061496,
  150: 1083282,
  151: 1203282,
  152: 1324782,
  153: 1447782,
  154: 1572282,
  155: 1698282,
  156: 1825782,
  157: 1954782,
  158: 2085282,
  159: 2217282,
  160: 2350782,
  161: 2485782,
  162: 2622282,
  163: 2760282,
  164: 2899782,
  165: 3040782,
  166: 3183282,
  167: 3327282,
  168: 3472782,
  169: 3619782,
  170: 3768282,
  171: 3918282,
  172: 4069782,
  173: 4222782,
  174: 4377282,
  175: 4533282,
  176: 4690782,
  177: 4849782,
  178: 5010282,
  179: 5172282,
  180: 5335782,
  181: 5500782,
  182: 5667282,
  183: 5835282,
  184: 6004782,
  185: 6175782,
  186: 6348282,
  187: 6522282,
  188: 6697782,
  189: 6874782,
  190: 7053282,
  191: 7233282,
  192: 7414782,
  193: 7597782,
  194: 7782282,
  195: 7968282,
  196: 8155782,
  197: 8344782,
  198: 8535282,
  199: 8727282,
  200: 8920782,
  201: 9115782,
  202: 9312282,
  203: 9510282,
  204: 9709782,
  205: 9910782,
  206: 10113282,
  207: 10317282,
  208: 10522782,
  209: 10729782,
  210: 10938282,
  211: 11148282,
  212: 11359782,
  213: 11572782,
  214: 11787282,
  215: 12003282,
  216: 12220782,
  217: 12439782,
  218: 12660282,
  219: 12882282,
  220: 13105782,
  221: 13330782,
  222: 13557282,
  223: 13785282,
  224: 14014782,
  225: 14245782,
  226: 14478282,
  227: 14712282,
  228: 14947782,
  229: 15184782,
  230: 15423282,
  231: 15663282,
  232: 15904782,
  233: 16147782,
  234: 16392282,
  235: 16638282,
  236: 16885782,
  237: 17134782,
  238: 17385282,
  239: 17637282,
  240: 17890782,
  241: 18145782,
  242: 18402282,
  243: 18660282,
  244: 18919782,
  245: 19180782,
  246: 19443282,
  247: 19707282,
  248: 19972782,
  249: 20239782,
  250: 20508282,
};

export default table;
