import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import ls from "local-storage";

const Context = createContext();

const storageKey = "nwxp.settings";

const SettingsContext = ({ children }) => {
  const [settings, setSettings] = useState({});

  const setSetting = useCallback((setting, value) => {
    setSettings((prev) => {
      const newSettings = {
        ...prev,
        [setting]: value,
      };

      ls(storageKey, JSON.stringify(newSettings));

      return newSettings;
    });
  }, []);

  useEffect(() => {
    const unparsedSavedSettings = ls(storageKey) || "{}";
    const savedSettings = JSON.parse(unparsedSavedSettings);

    if (Object.keys(savedSettings || {}).length) {
      setSettings(savedSettings);
    }
  }, []);

  return (
    <Context.Provider value={{ setSetting, settings }}>
      {children}
    </Context.Provider>
  );
};

export const useSettings = () => useContext(Context);

export { SettingsContext as SettingsContextProvider, Context as Consumer };
