import { Toast } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";

const ServiceWorkerNotification = () => {
  const [active, setActive] = useState(false);

  const handleHasUpdate = useCallback(() => {
    setActive(true);
  }, [setActive]);

  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    window.addEventListener("sw.updateAvailable", handleHasUpdate);

    return () =>
      window.removeEventListener("sw.updateAvailable", handleHasUpdate);
  });

  return active ? (
    <Toast
      content="Update available"
      action={{
        content: "Refresh",
        onAction: handleRefresh,
      }}
    />
  ) : null;
};

export default ServiceWorkerNotification;
