import React, { useEffect, useState, useCallback } from "react";

import { createClient } from "@supabase/supabase-js";
import { createContext } from "react";

const Context = createContext();

const supabaseUrl = "https://szpvwcnorfepwplwyayq.supabase.co";
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const { auth } = supabase;

const SupabaseContext = ({ children }) => {
  const [user, setUser] = useState();
  const [userData, setUserData] = useState();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const signInWithProvider = useCallback(async (provider) => {
    const { user, error } = await supabase.auth.signIn({
      provider,
    });

    if (error?.message) setError(error.message);
    if (user) setSuccess(true);
  }, []);

  const signUp = useCallback(async ({ email, password, username }) => {
    setSuccess(false);
    setError();

    const { user, error } = await auth.signUp(
      { email, password },
      {
        redirectTo: `${window.location.origin}/login`,
        data: {
          username,
        },
      }
    );

    if (error?.message) setError(error.message);
    if (user) setSuccess(true);
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    setSuccess(false);
    setError();

    const { user, error } = await auth.signIn({ email, password });

    if (error?.message) setError(error.message);
    if (user) setUser(user);
  }, []);

  const signOut = useCallback(async () => {
    const { error } = auth.signOut();

    if (error?.message) setError(error.message);
    setUser();
  }, []);

  const saveData = (type, value, callback) => {
    console.log(`Attempting to save ${type}:`, value);

    supabase
      .from("user_data")
      .update({ [type]: value }, { returning: "minimal" })
      .match({ user_id: user.id })
      .then(() => callback && callback());
  };

  const fetchUserData = useCallback(() => {
    setLoadingUserData(true);

    supabase
      .from("user_data")
      .select("sponsor, sponsor_type, pricesv2")
      .then((response) => {
        if (response.status === 200 && response.data.length) {
          setUserData({
            ...response.data[0],
            pricesv2: undefined,
            prices: response.data[0].pricesv2,
          });
        }

        setLoadingUserData(false);
      });
  }, []);

  useEffect(() => {
    auth.onAuthStateChange((_event, credential) => {
      if (credential?.user) setUser(credential.user);
    });
  }, []);

  useEffect(() => {
    if (!user) return setLoadingUserData(false);

    fetchUserData();
  }, [fetchUserData, user]);

  return (
    <Context.Provider
      value={{
        signUp,
        signIn,
        signInWithProvider,
        signOut,
        user,
        userData: userData || {},
        loadingUserData,
        error,
        success,
        saveData,
        fetchUserData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { SupabaseContext as Provider, Context as Consumer };
