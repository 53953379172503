const table = {
  1: 72,
  2: 155,
  3: 249,
  4: 353,
  5: 469,
  6: 595,
  7: 732,
  8: 880,
  9: 1039,
  10: 1208,
  11: 1388,
  12: 1579,
  13: 1781,
  14: 1993,
  15: 2217,
  16: 2451,
  17: 2696,
  18: 2952,
  19: 3219,
  20: 3496,
  21: 3784,
  22: 4083,
  23: 4393,
  24: 4713,
  25: 5045,
  26: 5387,
  27: 5740,
  28: 6104,
  29: 6479,
  30: 6864,
  31: 7260,
  32: 7667,
  33: 8085,
  34: 8513,
  35: 8953,
  36: 9403,
  37: 9864,
  38: 10336,
  39: 10819,
  40: 11312,
  41: 11816,
  42: 12331,
  43: 12857,
  44: 13393,
  45: 13941,
  46: 14499,
  47: 15068,
  48: 15648,
  49: 16239,
  50: 16840,
  51: 17704,
  52: 18626,
  53: 19606,
  54: 20643,
  55: 21738,
  56: 22890,
  57: 24100,
  58: 25368,
  59: 26693,
  60: 28076,
  61: 29516,
  62: 31014,
  63: 32570,
  64: 34183,
  65: 35854,
  66: 37582,
  67: 39368,
  68: 41212,
  69: 43113,
  70: 45072,
  71: 47088,
  72: 49162,
  73: 51294,
  74: 53483,
  75: 55730,
  76: 58034,
  77: 60396,
  78: 62816,
  79: 65293,
  80: 67828,
  81: 70420,
  82: 73070,
  83: 75778,
  84: 78543,
  85: 81366,
  86: 84246,
  87: 87184,
  88: 90180,
  89: 93233,
  90: 96344,
  91: 99512,
  92: 102738,
  93: 106022,
  94: 109363,
  95: 112762,
  96: 116218,
  97: 119732,
  98: 123304,
  99: 126933,
  100: 130620,
  101: 137532,
  102: 144790,
  103: 152394,
  104: 160343,
  105: 168638,
  106: 177278,
  107: 186264,
  108: 195596,
  109: 205273,
  110: 215296,
  111: 225664,
  112: 236378,
  113: 247438,
  114: 258843,
  115: 270594,
  116: 282690,
  117: 295132,
  118: 307920,
  119: 321053,
  120: 334532,
  121: 348356,
  122: 362526,
  123: 377042,
  124: 391903,
  125: 407110,
  126: 422662,
  127: 438560,
  128: 454804,
  129: 471393,
  130: 488328,
  131: 505608,
  132: 523234,
  133: 541206,
  134: 559523,
  135: 578186,
  136: 597194,
  137: 616548,
  138: 636248,
  139: 656293,
  140: 676684,
  141: 697420,
  142: 718502,
  143: 739930,
  144: 761703,
  145: 783822,
  146: 806286,
  147: 829096,
  148: 852252,
  149: 875753,
  150: 899600,
  151: 950288,
  152: 1002820,
  153: 1057195,
  154: 1113413,
  155: 1171474,
  156: 1231378,
  157: 1293126,
  158: 1356717,
  159: 1422151,
  160: 1489428,
  161: 1558548,
  162: 1629512,
  163: 1702319,
  164: 1776969,
  165: 1853462,
  166: 1931798,
  167: 2011978,
  168: 2094001,
  169: 2177867,
  170: 2263576,
  171: 2351128,
  172: 2440524,
  173: 2531763,
  174: 2624845,
  175: 2719770,
  176: 2816538,
  177: 2915150,
  178: 3015605,
  179: 3117903,
  180: 3222044,
  181: 3328028,
  182: 3435856,
  183: 3545527,
  184: 3657041,
  185: 3770398,
  186: 3885598,
  187: 4002642,
  188: 4121529,
  189: 4242259,
  190: 4364832,
  191: 4489248,
  192: 4615508,
  193: 4743611,
  194: 4873557,
  195: 5005346,
  196: 5138978,
  197: 5274454,
  198: 5411773,
  199: 5550935,
  200: 5691940,
  201: 5833636,
  202: 5977636,
  203: 6123940,
  204: 6272548,
  205: 6423460,
  206: 6576676,
  207: 6732196,
  208: 6890020,
  209: 7050148,
  210: 7212580,
  211: 7377316,
  212: 7544356,
  213: 7713700,
  214: 7885348,
  215: 8059300,
  216: 8235556,
  217: 8414116,
  218: 8593316,
  219: 8773156,
  220: 8953636,
  221: 9134756,
  222: 9316516,
  223: 9498916,
  224: 9681956,
  225: 9865636,
  226: 10049956,
  227: 10234916,
  228: 10420516,
  229: 10606756,
  230: 10793636,
  231: 10981156,
  232: 11169316,
  233: 11358116,
  234: 11547556,
  235: 11737636,
  236: 11928356,
  237: 12119716,
  238: 12311716,
  239: 12504356,
  240: 12697636,
  241: 12891556,
  242: 13086116,
  243: 13281316,
  244: 13477156,
  245: 13673636,
  246: 13870756,
  247: 14068516,
  248: 14266916,
  249: 14465956,
  250: 14665636,
};

export default table;
