const table = {
  1: 102,
  2: 210,
  3: 324,
  4: 443,
  5: 568,
  6: 699,
  7: 835,
  8: 977,
  9: 1124,
  10: 1277,
  11: 1436,
  12: 1600,
  13: 1770,
  14: 1945,
  15: 2126,
  16: 2313,
  17: 2505,
  18: 2703,
  19: 2906,
  20: 3115,
  21: 3330,
  22: 3550,
  23: 3776,
  24: 4007,
  25: 4244,
  26: 4487,
  27: 4735,
  28: 4989,
  29: 5248,
  30: 5513,
  31: 5784,
  32: 6060,
  33: 6342,
  34: 6629,
  35: 6922,
  36: 7221,
  37: 7525,
  38: 7835,
  39: 8150,
  40: 8471,
  41: 8798,
  42: 9130,
  43: 9468,
  44: 9811,
  45: 10160,
  46: 10515,
  47: 10875,
  48: 11241,
  49: 11612,
  50: 11989,
  51: 13902,
  52: 15843,
  53: 17812,
  54: 19809,
  55: 21834,
  56: 23887,
  57: 25968,
  58: 28077,
  59: 30214,
  60: 32379,
  61: 34572,
  62: 36794,
  63: 39044,
  64: 41322,
  65: 43628,
  66: 45962,
  67: 48324,
  68: 50714,
  69: 53132,
  70: 55578,
  71: 58052,
  72: 60554,
  73: 63084,
  74: 65642,
  75: 68228,
  76: 70842,
  77: 73484,
  78: 76154,
  79: 78852,
  80: 81578,
  81: 84332,
  82: 87115,
  83: 89926,
  84: 92765,
  85: 95632,
  86: 98527,
  87: 101450,
  88: 104401,
  89: 107380,
  90: 110387,
  91: 113422,
  92: 116485,
  93: 119576,
  94: 122695,
  95: 125842,
  96: 129017,
  97: 132220,
  98: 135451,
  99: 138710,
  100: 141997,
  101: 159210,
  102: 176544,
  103: 193999,
  104: 211575,
  105: 229272,
  106: 247090,
  107: 265030,
  108: 283090,
  109: 301272,
  110: 319575,
  111: 337999,
  112: 356544,
  113: 375210,
  114: 393997,
  115: 412906,
  116: 431935,
  117: 451086,
  118: 470358,
  119: 489751,
  120: 509265,
  121: 528900,
  122: 548656,
  123: 568534,
  124: 588532,
  125: 608652,
  126: 628893,
  127: 649255,
  128: 669738,
  129: 690342,
  130: 711067,
  131: 731914,
  132: 752881,
  133: 773970,
  134: 795180,
  135: 816511,
  136: 837963,
  137: 859536,
  138: 881230,
  139: 903046,
  140: 924982,
  141: 947040,
  142: 969219,
  143: 991519,
  144: 1013940,
  145: 1036482,
  146: 1059145,
  147: 1081930,
  148: 1104835,
  149: 1127862,
  150: 1151010,
  151: 1278510,
  152: 1407604,
  153: 1538292,
  154: 1670574,
  155: 1804449,
  156: 1939918,
  157: 2076981,
  158: 2215638,
  159: 2355888,
  160: 2497732,
  161: 2641170,
  162: 2786202,
  163: 2932827,
  164: 3081046,
  165: 3230859,
  166: 3382266,
  167: 3535266,
  168: 3689860,
  169: 3846048,
  170: 4003830,
  171: 4163205,
  172: 4324174,
  173: 4486737,
  174: 4650894,
  175: 4816644,
  176: 4983988,
  177: 5152926,
  178: 5323458,
  179: 5495583,
  180: 5669302,
  181: 5844615,
  182: 6021522,
  183: 6200022,
  184: 6380116,
  185: 6561804,
  186: 6745086,
  187: 6929961,
  188: 7116430,
  189: 7304493,
  190: 7494150,
  191: 7685400,
  192: 7878244,
  193: 8072682,
  194: 8268714,
  195: 8466339,
  196: 8665558,
  197: 8866371,
  198: 9068778,
  199: 9272778,
  200: 9478372,
  201: 9685560,
  202: 9894198,
  203: 10104297,
  204: 10315866,
  205: 10528916,
  206: 10743458,
  207: 10959502,
  208: 11177058,
  209: 11396137,
  210: 11616749,
  211: 11838906,
  212: 12062618,
  213: 12287896,
  214: 12514751,
  215: 12743194,
  216: 12973236,
  217: 13204888,
  218: 13438162,
  219: 13673069,
  220: 13909620,
  221: 14147827,
  222: 14387701,
  223: 14629254,
  224: 14872498,
  225: 15117445,
  226: 15364106,
  227: 15612494,
  228: 15862621,
  229: 16114499,
  230: 16368140,
  231: 16623556,
  232: 16880760,
  233: 17139765,
  234: 17400583,
  235: 17663226,
  236: 17927708,
  237: 18194041,
  238: 18462239,
  239: 18732314,
  240: 19004280,
  241: 19278149,
  242: 19553935,
  243: 19831652,
  244: 20111313,
  245: 20392932,
  246: 20676522,
  247: 20962097,
  248: 21249671,
  249: 21539258,
  250: 21830872,
};

export default table;
