import "@shopify/polaris/build/esm/styles.css";
import "./index.css";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import Navigator from "./Navigator";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

require("dotenv").config();

console.log("Using cache", process.env.REACT_APP_VERSION_UNIQUE_STRING);

ReactDOM.render(
  <React.StrictMode>
    <Navigator />
  </React.StrictMode>,
  document.getElementById("root")
);

navigator.serviceWorker.getRegistrations().then(function (registrations) {
  if (!registrations.length) {
    console.log("No serviceWorker registrations found.");
    return;
  }
  for (let registration of registrations) {
    registration.unregister().then(function (boolean) {
      console.log(
        boolean ? "Successfully unregistered" : "Failed to unregister",
        "ServiceWorkerRegistration\n" +
          (registration.installing
            ? "  .installing.scriptURL = " +
              registration.installing.scriptURL +
              "\n"
            : "") +
          (registration.waiting
            ? "  .waiting.scriptURL = " + registration.waiting.scriptURL + "\n"
            : "") +
          (registration.active
            ? "  .active.scriptURL = " + registration.active.scriptURL + "\n"
            : "") +
          "  .scope: " +
          registration.scope +
          "\n"
      );
    });
  }
});
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
