import { logEvent as fbLogEvent, getAnalytics } from "firebase/analytics";
import { useCallback, useEffect, useState } from "react";

import { initializeApp } from "firebase/app";
import { useLocation } from "react-router";

const firebaseConfig = {
  apiKey: "AIzaSyDBtOhgO9tnzJbtj5urn0z5yfp74sj4lYo",
  authDomain: "newworld-xp.firebaseapp.com",
  projectId: "newworld-xp",
  storageBucket: "newworld-xp.appspot.com",
  messagingSenderId: "867545758178",
  appId: "1:867545758178:web:f613c5dcd1132ba60c61bb",
  measurementId: "G-JZG051TL00",
};

const useAnalytics = () => {
  const [analytics, setAnalytics] = useState();
  const { 1: setLastPage } = useState();

  const location = useLocation();

  const logEvent = useCallback(
    (event, attrs) => fbLogEvent(analytics, event, attrs),
    [analytics]
  );
  useEffect(() => {
    const app = initializeApp(firebaseConfig);

    setAnalytics(getAnalytics(app));
  }, []);

  useEffect(() => {
    if (analytics) {
      const pagePath = location.pathname + location.search;

      setLastPage((prev) => {
        if (prev !== pagePath) {
          logEvent("page_view", { page_path: pagePath });

          return pagePath;
        } else return prev;
      });
    }
  }, [location, analytics, logEvent, setLastPage]);
};

export default useAnalytics;
